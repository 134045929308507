.banner-container {
  position: relative;
  text-align: center;
}

.banner-background {
  top: 0;
  left: 0;
  width: 100vw;
  height: 60vh;
  background-color: #01073d; /* Change to desired background color */
  opacity: 1; /* Adjust opacity as needed */
}

.banner-text {
  color: white;
  font-size: 40px;
  padding-top: 20vh; /* Adjust margin as needed */
}

.sub-description {
  color: white;
  font-size: 20px;
}
