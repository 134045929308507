
.body {
    /* margin-left: 1em;
    margin-right: 1em; */
}

.footer {
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f8f8;
    color: #888;
    font-size: 12px;
    text-align: center;
    padding: 10px 0;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  