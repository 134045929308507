.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
    z-index: 1000;
  }
  
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  .navbar ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .navbar ul li:last-child {
    margin-right: 0;
  }
  
  .navbar ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
  }
  
  .navbar ul li a:hover {
    color: #ffc107;
  }
  